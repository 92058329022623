import { type } from "os";
import React from "react";

type Props = {
  title: string;
  subtitle: string;
  text: string;
};

export default function SectionHeader({ title, subtitle, text }: Props) {
  return (
    <div className="mx-auto max-w-6xl">
      <div className="text-center py-8 md:py-16 p-8 md:p-56">
        <h6 className="text-accent text-base font-semibold">{title}</h6>
        <h2 className="text-2xl md:text-4xl font-semibold mt-3">{subtitle}</h2>
        <p className="md:text-lg text-base mt-5">{text}</p>
      </div>
    </div>
  );
}
