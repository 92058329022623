import React from "react";
type Props = {
  text: string;
  leftImage?: {
    id: string;
    sourceUrl: string;
  };
  rightImage: {
    id: string;
    sourceUrl: string;
  };
};
export default function WinnerItemLeft({ text, leftImage, rightImage }: Props) {
  return (
    <div className="flex-col md:flex-row justify-center items-center">
      <div className="flex items-end">
        {leftImage?.sourceUrl && (
          <img className="hidden md:block object-contain z-10 rounded-bl-lg h-44" src={leftImage.sourceUrl} />
        )}
        <div className="flex items-center">
          <div className="flex items-center bg-cellBackground rounded-lg mr-6 md:-ml-10 md:w-72 md:h-24">
            <p className="text-white m-4 md:m-8">{text}</p>
          </div>
          <img className="object-cover shadow-heroImage rounded-2xl w-24 h-24" src={rightImage.sourceUrl} />
        </div>
      </div>
    </div>
  );
}
