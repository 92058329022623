import React from "react";
type Props = {
  text: string;
};
export default function HeaderLabel({ text }: Props) {
  return (
    <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-headlineGradientStart to-headlineGradientEnd text-5xl md:text-5xl tracking-tighter font-black pt-2 pb-2">
      {text}
    </h2>
  );
}
