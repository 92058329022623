import React from "react";
import EmailSignUp from "./EmailSignUp";
import WinnerItemLeft from "./winners/WinnerItemLeft";
import WinnerItemRight from "./winners/WinnerItemRight";

type Props = {
  heroSection: {
    title: string;
    subtitle: string;
    emailPlaceholderText: string;
    signUpButtonText: string;
    imagesSection: {
      text1: string;
      text2: string;
      text3: string;
      image1: {
        id: string;
        sourceUrl: string;
      };
      image2: {
        id: string;
        sourceUrl: string;
      };
      image3: {
        id: string;
        sourceUrl: string;
      };
      image4: {
        id: string;
        sourceUrl: string;
      };
      image5: {
        id: string;
        sourceUrl: string;
      };
    };
    footerSection: {
      text: string;
      image1: {
        id: string;
        sourceUrl: string;
      };
      image2: {
        id: string;
        sourceUrl: string;
      };
      image3: {
        id: string;
        sourceUrl: string;
      };
    };
  };
};
export default function HeroSection({
  heroSection: {
    title,
    subtitle,
    emailPlaceholderText,
    signUpButtonText,
    imagesSection: {
      text1,
      text2,
      text3,
      image1,
      image2,
      image3,
      image4,
      image5,
    },
    footerSection: {
      text: footerText,
      image1: footerImage1,
      image2: footerImage2,
      image3: footerImage3,
    },
  },
}: Props) {
  return (
    <div className="mx-auto max-w-7xl px-10 xl:px-0 py-10 font-['SpaceGrotesk']">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 md:pr-8">
          <EmailSignUp
            title={title}
            subtitle={subtitle}
            emailPlaceholderText={emailPlaceholderText}
            signUpButtonText={signUpButtonText}
          />
        </div>

        <div className="mt-16 md:mt-0 flex flex-col justify-center items-center text-xs md:text-base">
          {image1?.sourceUrl && (
            <div className="md:ml-24">
              <WinnerItemRight
                text={text1}
                leftImage={image1}
                rightImage={image2}
              />
            </div>
          )}
          <div className="mt-6 md:-mt-14">
            {image3?.sourceUrl && (
              <WinnerItemLeft
                text={text2}
                leftImage={image3}
                rightImage={image4}
              />
            )}
          </div>
          {image5?.sourceUrl && (
            <div className="md:ml-24 mt-6">
              <WinnerItemRight
                text={text3}
                leftImage={image5}
                rightImage={undefined}
              />
            </div>
          )}
        </div>
      </div>

      <p className="text-white pt-16 text-sm mb-2">{footerText}</p>
      <div className="flex h-4">
        {footerImage1?.sourceUrl && (
          <img className="object-contain" src={footerImage1.sourceUrl} />
        )}
        {footerImage2?.sourceUrl && (
          <img className="object-contain ml-4" src={footerImage2.sourceUrl} />
        )}
        {footerImage3?.sourceUrl && (
          <img className="object-contain ml-4" src={footerImage3.sourceUrl} />
        )}
      </div>
    </div>
  );
}
