import React from "react";
import feature1Image from "../images/feature1.png";
import feature2Image from "../images/feature2.png";
import feature3Image from "../images/feature3.png";
import feature1Icon from "../images/feature_icon1.png";
import feature2Icon from "../images/feature_icon2.png";
import feature3Icon from "../images/feature_icon3.png";
import BulletPoint from "./BulletPoint";

export default function FeatureOverview() {
  return (
    <div className="mx-auto max-w-6xl pb-12 py-16">
      <div className="flex flex-col space-y-24 md:space-y-40">
        {/* Feature 1: Stay in the loop */}
        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="md:w-1/2 px-8">
            <img
              className="h-12 w-12"
              src={feature1Icon}
              alt="Feature 1 Icon"
            />
            <h3 className="text-2xl md:text-4xl font-semibold pt-4">
              Stay in the loop - see all your rewards
            </h3>
            <p className="mt-5">
              When utilizing rewards for your NFT project it's hard to keep
              track of what's going on.
            </p>
            <div className="pl-2 pt-4 space-y-4">
              <BulletPoint text="See how your reward promotion performs" />
              <BulletPoint text="Keep track of running rewards" />
              <BulletPoint text="Plan upcoming rewards and push your NFT project" />
            </div>
          </div>
          <div className="bg-featureImageBackground m-6 md:m-0 md:w-1/2 rounded-3xl inline-flex justify-center p-4 md:p-8 md:ml-12">
            <img
              className="rounded-md"
              src={feature1Image}
              alt="Feature 1 Image"
            />
          </div>
        </div>

        {/* Feature 2: Choose claimers */}
        <div className="flex md:flex-row flex-col-reverse justify-center items-center">
          <div className="bg-featureImageBackground rounded-3xl inline-flex justify-center m-6 md:m-0 md:w-1/2 p-4 md:p-8 md:mr-12">
            <img
              className="rounded-md"
              src={feature2Image}
              alt="Feature 2 Image"
            />
          </div>
          <div className="md:w-1/2 px-8">
            <img
              className="h-12 w-12"
              src={feature2Icon}
              alt="Feature 2 Icon"
            />
            <h3 className="text-2xl md:text-4xl font-semibold mt-3">
              Choose who can claim rewards
            </h3>
            <p className="mt-5">
              Decide if you want to raffle a reward or if any NFT that comes
              with certain traits can claim.
            </p>
          </div>
        </div>

        {/* Feature 3: Keep track */}
        <div className="flex md:flex-row flex-col justify-center items-center mt-16 md:mt-8">
          <div className="md:w-1/2 px-8">
            <img
              className="h-12 w-12"
              src={feature3Icon}
              alt="Feature 3 Icon"
            />
            <h3 className="text-2xl md:text-4xl font-semibold mt-3">
              Keep track of claims
            </h3>
            <p className="mt-5">
              Get a smart to-do list to handle your claims. Mark them as
              complete as soon as you've sent out the reward.
            </p>
          </div>
          <div className="bg-featureImageBackground rounded-3xl inline-flex justify-center m-6 md:m-0 md:w-1/2 p-4 md:p-8 md:mr-12">
            <img
              className="rounded-md"
              src={feature3Image}
              alt="Feature 3 Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
