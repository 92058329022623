import { graphql, PageProps } from "gatsby";
import * as React from "react";
import ContactSection from "../components/ContactSection";
import FeatureSection from "../components/FeatureSection";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import MainMenu from "../components/MainMenu";
import TestimonialSection from "../components/TestimonialSection";
import charamzaPic from "../images/charamza.png";
import oefLogo from "../images/oefb_logo_colored.png";

export default function IndexPage({
  data,
}: PageProps<Queries.TypegenPageQuery>) {
  const mainPage = data.wpPage;
  const heroSection = mainPage?.acfPage?.heroSection;

  return (
    <div className="bg-gradient-to-r from-backgroundGradientStart to-backgroundGradientEnd">
      <MainMenu />
      <HeroSection heroSection={heroSection} />
      <FeatureSection />
      <ContactSection />
      <TestimonialSection
        companyLogoSrc={oefLogo}
        testimonialText="We've been using nftclaimer.xyz to kick start our OEFB NFT collection. Holders easily claimed tickets for sold-out home games, meet & greets with players and more."
        user={{
          profilePicSrc: charamzaPic,
          name: "Simon-Peter Charamza",
          title:
            "Head of Development & Digital Projects, ÖFB (Austrian Football Association)",
        }}
      />
      <Footer />
    </div>
  );
}

export const query = graphql`
  query IndexPage {
    wpPage(slug: { eq: "title-page" }) {
      title
      id
      content
      slug
      status
      template {
        templateName
      }
      title
      date
      acfPage {
        heroSection {
          title
          subtitle
          emailPlaceholderText
          signUpButtonText
          imagesSection {
            text1
            text2
            text3
            image1 {
              id
              sourceUrl
            }
            image2 {
              id
              sourceUrl
            }
            image3 {
              id
              sourceUrl
            }
            image4 {
              id
              sourceUrl
            }
            image5 {
              id
              sourceUrl
            }
          }
          footerSection {
            text
            image1 {
              id
              sourceUrl
            }
            image2 {
              id
              sourceUrl
            }
            image3 {
              id
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
