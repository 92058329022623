import React from "react";
import TestimonialUser from "./TestimonialUser";

type Props = {
  companyLogoSrc: string;
  testimonialText: string;
  user: {
    profilePicSrc: string;
    name: string;
    title: string;
  };
};

export default function TestimonialSection({
  companyLogoSrc,
  testimonialText,
  user,
}: Props) {
  {
    return (
      <section className="bg-white py-16 md:py-24 p-8 md:p-32 text-center">
        <div className="mx-auto max-w-6xl">
          <div className="flex justify-center">
            <img className="w-32" src={companyLogoSrc} alt="Company Image" />
          </div>
          <p className="text-lg md:text-2xl text-neutral-900 my-8">
            "{testimonialText}"
          </p>
          <TestimonialUser user={user} />
        </div>
      </section>
    );
  }
}
