import React, { useState } from "react";
import DefaultButton from "./DefaultButton";
import HeaderLabel from "./HeaderLabel";
import Modal from "./Modal";

type Props = {
  title: string;
  subtitle: string;
  emailPlaceholderText: string;
  signUpButtonText: string;
};

export default function EmailSignUp({
  title,
  subtitle,
  signUpButtonText,
}: Props) {
  const [showEmailDialog, setShowEmailDialog] = useState(false);

  return (
    <div>
      <div className="mx-auto max-w-7xl lg:py-10">
        <HeaderLabel text={title} />

        <p className="text-white text-2xl md:text-3xl">{subtitle}</p>

        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-8 align-middle items-center mt-8 xl:mt-0">
          <div>
            <DefaultButton
              text={signUpButtonText}
              onClick={() => setShowEmailDialog(true)}
            />
            <Modal
              isOpen={showEmailDialog}
              closeModal={() => setShowEmailDialog(false)}
            >
              <div className="w-[80vw] lg:w-[40vw] h-[80vh] md:h-[70vh]">
                <iframe
                  src="https://3ce1f4d4.sibforms.com/serve/MUIEAND-BfdrWvvOhr4bXAjU5YgZ9LyxZkDP6uJLW7yGs-TmNxd75HesYAld7XtaTymuvoviuiHa3sIlnQDlUMD5suxb4LjHG2fZCeNbcKk7Z_9fK35tQYFwKNkd-gylkhOamd0jSXaOHDFpR9jnZOsRpux4dOagBfaCorAXQ6asoG8kCwrZ5XW_26fT2gFFKqOq2t91tUKZwJfj"
                  scrolling="auto"
                  allowFullScreen={true}
                  className="h-full w-full"
                />
              </div>
            </Modal>
          </div>
          <div className="align-middle w-full">
            <a
              href="https://www.producthunt.com/posts/nftclaimer-xyz?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-nftclaimer&#0045;xyz"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=364709&theme=dark"
                alt="nftclaimer&#0046;xyz - Reward&#0032;your&#0032;NFT&#0032;community | Product Hunt"
                className="w-64 h-24"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
