import React from "react";

type Props = {
  user: {
    profilePicSrc: string;
    name: string;
    title: string;
  };
};

export default function TestimonialUser({ user }: Props) {
  return (
    <div className="text-center">
      <div className="flex justify-center">
        <img
          className="w-16 h-16"
          src={user.profilePicSrc}
          alt={user.name + " Image"}
        />
      </div>
      <h6 className="md:text-xl text-neutral-900 font-semibold mt-4">
        {user.name}
      </h6>
      <p className="text-sm md:text-lg">{user.title}</p>
    </div>
  );
}
