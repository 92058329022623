import React from "react";
import DefaultButton from "./DefaultButton";

export default function ContactSection() {
  return (
    <section className="bg-footerBackground">
      <div className="px-8 xl:px-0 py-24 flex flex-col md:flex-row justify-between items-center mx-auto max-w-7xl">
        <div className="flex flex-col">
          <h2 className="text-white text-2xl md:text-4xl font-semibold mt-3">
            Do you need this for your NFT project?
          </h2>
          <p className="text-slate-200 text-base mt-5">
            Get in touch with us and we will quickly set you up.
          </p>
        </div>
        <div className="flex w-full md:w-1/3 mt-8 md:mt-0">
          <DefaultButton
            text="Contact us"
            onClick={() => {
              window.open("mailto:hello@nftclaimer.xyz?subject=Contact%20us");
            }}
          />
        </div>
      </div>
    </section>
  );
}
