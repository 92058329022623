import React from "react";
type Props = {
  text: string;
  leftImage: {
    id: string;
    sourceUrl: string;
  };
  rightImage?: {
    id: string;
    sourceUrl: string;
  };
};
export default function WinnerItemRight({
  text,
  leftImage,
  rightImage,
}: Props) {
  return (
    <div className="flex-col md:flex-row justify-center items-center">
      <div className="flex items-end">
        <div className="flex items-center">
          <img className="object-contain shadow-heroImage rounded-2xl w-24" src={leftImage.sourceUrl} />
          <div className="flex items-center bg-cellBackground rounded-lg ml-6 md:w-72 md:h-24">
            <p className="text-white m-4 md:m-8">{text}</p>
          </div>
        </div>
        {rightImage?.sourceUrl && (
          <img className="hidden md:block object-contain -ml-14 rounded-br-lg h-36" src={rightImage.sourceUrl} />
        )}
      </div>
    </div>
  );
}
