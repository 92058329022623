import React from "react";

type Props = {
  text: string;
  onClick: () => void;
};

export default function DefaultButton({ text, onClick }: Props) {
  return (
    <button
      onClick={() => onClick()}
      className="block w-full rounded-md border border-transparent bg-gradient-to-tr from-purple-900 to-purple-700 px-6 md:px-10 py-3 text-base font-medium text-white hover:bg-gradient-r hover:from-purple-600 hover:to-purple-500"
    >
      {text}
    </button>
  );
}
