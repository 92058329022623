import React from "react";
import checkIcon from "../images/check_icon.png";

type Props = {
  text: string;
};

export default function BulletPoint({ text }: Props) {
  return (
    <div className="flex items-center">
      <img className="h-7 w-7" src={checkIcon} alt="Bullet Point Icon" />
      <p className="pl-2">{text}</p>
    </div>
  );
}
