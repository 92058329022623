import React from "react";
import FeatureOverview from "./FeatureOverview";
import SectionHeader from "./SectionHeader";

export default function FeatureSection() {
  return (
    <section id="features" className="bg-white">
      <SectionHeader
        title="Features"
        subtitle="Keep focusing on your project - not on reward fullfillment"
        text="With nftclaimer.xyz you can fully
        automate rewards for your NFT community."
      />
      <FeatureOverview />
    </section>
  );
}
